<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">车载以太网测试系统</h1>
        <p data-caption-delay="100" class="wow fadeInRight">车载以太网1-7层协议一致性测试的自动化测试解决方案</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)"><div>立即咨询</div></div>
      </div>
    </div>
    <section class="solution-intros wow fadeInLeft">
      <div class="container">
        <div class="normal-title">解决方案介绍</div>
        <div class="all-intro">
          <el-row>
            <el-col :span="24">
              <div class="intro-text" style="width: 100%;">
                <p>高级信息娱乐系统、自动泊车、驾驶辅助系统（ADAS）等技术的飞速发展，导致对车载网络带宽的要求越来越高，甚至已经超出了CAN/CANFD等传统网络的承载能力。为了解决这些问题，具备更高传输速率 （100 Mb/s & 1000 Mb/s）的车载以太网应运而生。在应用的过程中，为了保证车载以太网的稳定性与安全性，就需要对其开展测试工作。车载以太网测试系统是东信创智解决方案中面向汽车OEM和供应商，提供车载以太网1-7层协议一致性测试的自动化测试系统。</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </section>
    <section class="vehicle-solution-sections ethernet-solution-function wow fadeInRight">
      <div class="container">
        <div class="normal-title">功能特点</div>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
            <div class="all-function">
              <ul class="priority-list">
                <li>业内顶级的合作伙伴Vector、Spirent、R&S等</li>
                <li>丰富的系统集成项目经验及测试执行经验</li>
                <li>灵活的系统配置（根据不同测试需求，提供多种可选配置）</li>
                <li>便捷的自动化测试操作</li>
                <li>完整的测试覆盖度</li>
              </ul>
            </div>
          </el-col>
          <el-col :xs="0" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="function-picture">
              <img src="../../../assets/images/solution-ethernet-function-pic.png" alt="">
            </div>
          </el-col>
        </el-row>
      </div>
    </section>
    <section class="ethernet-solution-components wow fadeInLeft">
      <div class="container">
        <div class="normal-title">核心组件</div>
        <div class="solution-components">
          <div class="components-content">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <div class="components-picture">
                  <img src="../../../assets/images/solution-ethernet-components.png" alt="">
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <div class="components-table">
                  <el-table
                    :data="tableData"
                    :span-method="arraySpanMethod"
                    :header-cell-style="{background:'#296CA8',color:'#FFFFFF'}"
                    border
                    size="small"
                  >
                    <el-table-column prop="equipBrand" label="集成设备品牌" width="120"/>
                    <el-table-column prop="equipName" label="设备名称" width="140">
                      <template slot-scope="scope">
                        <div v-html="scope.row.equipName"></div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="testContent" label="测试内容">
                      <template slot-scope="scope">
                        <div v-html="scope.row.testContent"></div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </section>
    <section class="network-solution-system-picture wow fadeInRight">
      <div class="container">
        <div class="normal-title">系统图片</div>
        <div class="all-system-picture">
          <div class="system-picture">
            <img src="../../../assets/images/solution-ethernet-system-pic.png" alt="">
          </div>
        </div>
      </div>
    </section>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from '@/components/MainNav'
import mainFooter from '@/components/MainFooter'
import bzInfo from '@/components/BzInfo'
export default {
  name: "ethernet",
  components: {
    mainNav,
    mainFooter,
    bzInfo
  },
  data(){
    return {
      activeIndex: '2-3',
      swiperOption: {
        spaceBetween: 20, // 图片之间的间距
        centeredSlides: false, // 居中还是从图1开始
        slidesPerView: 3, // 一屏幕显示几个? 数字或者默认  auto 自动。
        notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
        // loop: true, // 循环吗
        initialSlide: 0, // 从第几个开始
        autoplay: {
          delay: 5000, // 等5秒下一个
          disableOnInteraction: false // 中间滑动一下，取消自动吗？
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }, // 下按钮
        speed: 800, // 滑动时候动画的速度
        paginationClickable: true, // 下面按钮让点吗
        navigation: {
          prevEl: '.swiper-button-prev', // 左侧按钮
          nextEl: '.swiper-button-next' // 右侧按钮
        },
        // effect: 'fade', // 渐入效果
        watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
        watchSlidesVisibility: true  // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
        // autoHeight: true  // 图片高度自适应
      },
      tableData: [
        {
          equipBrand: "Vector",
          equipName: "CANoe",
          testContent:
            "TC8 3.0 layer1 IOP\n" +
            "TC8 3.0 layer3-7\n" +
            "应用层测试（SOME/IP等）\n"
        },
        {
          equipBrand: "Vector",
          equipName: "VN5650",
          testContent: "TC8 3.0 layer1 IOP\n" +
            "TC8 3.0 layer3-7\n" +
            "应用层测试（SOME/IP等）\n"
        },
        {
          equipBrand: "Vector",
          equipName: "VT System",
          testContent: "TC8 3.0 layer1 IOP\n" +
            "TC8 3.0 layer3-7\n" +
            "应用层测试（SOME/IP等）\n"
        },
        {
          equipBrand: "R&S",
          equipName: "R&S RTO2024\n" +
            "矢网R&S ZND\n",
          testContent: "100BASE-T1 PMA（TC8）\n" +
            "1000BSE-T1 PMA（TC8）\n" +
            "100BASE-TX物理层(ANSI X3.263-1995)\n"
        },
        {
          equipBrand: "Spirent",
          equipName: "Spirent C50/C1",
          testContent: "TC8 3.0 layer2\n" +
            "TC8 3.0 layer3-7\n" +
            "AUTOSAR TCP/UDP/IP\n" +
            "Switch（RFC2544&RFC2889)\n" +
            "AVB/TSN\n"
        }
      ],
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
    }
  },
  mounted() {
    document.title = "车载以太网测试系统 - 解决方案 - 东信创智";
    new this.$wow.WOW().init();
  },
  methods: {
    linkUrl(url){
      window.open(url,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
    // eslint-disable-next-line no-unused-vars
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 && (rowIndex === 0 || rowIndex === 1 || rowIndex === 2)) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
      if (columnIndex === 2 && (rowIndex === 0 || rowIndex === 1 || rowIndex === 2)) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
